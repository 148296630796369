<template>
  <div class="alert-sidebar">
    <div class="alert-sidebar--content">
      <v-form ref="form">
        <CRInput
          :id="`alert-title-${externalId}`"
          v-model="title"
          label="Title"
          placeholder="Enter alert title"
          :loading="loading"
          :rules="[
            (val) => !!val || 'Title is Required',
            (val) => (!!val && val.length < 40) || 'Max 40 characters',
          ]"
          :disabled="isModeView"
        />
        <div v-if="isModeView">
          <p class="margin-a-0">Text</p>
          <div v-html="text" />
        </div>
        <CRRichText
          v-else
          :id="`alert-text-${externalId}`"
          :note="text"
          class="padding-t-2 mb-4"
          label="Text"
          placeholder="Enter alert text here."
          :loading="loading"
          :rows="4"
          auto-grow
          :rules="[
            (val) => !!val || 'Title is Required',
            (val) => (!!val && val.length < 200) || 'Max 200 characters',
          ]"
          @htmlchange="(val) => (text = val)"
        />
        <div class="d-flex padding-t-2">
          <CRInput
            v-model="startEffectiveDate"
            label="Start Date"
            type="date"
            :loading="loading"
            class="padding-r-2"
            :disabled="isModeView"
            clear-icon="replay"
            :clearable="true"
            hide-details
          />
          <CRInput
            v-model="startEffectiveTime"
            label="Start Time"
            type="time"
            :loading="loading"
            :disabled="isModeView"
          />
        </div>
        <div class="d-flex padding-t-2">
          <CRInput
            v-model="endEffectiveDate"
            label="End Date"
            type="date"
            :loading="loading"
            class="padding-r-2"
            :disabled="isModeView"
            clear-icon="replay"
            :clearable="true"
            hide-details
          />
          <CRInput
            v-model="endEffectiveTime"
            label="End Time"
            type="time"
            :loading="loading"
            :disabled="isModeView"
          />
        </div>
        <div class="d-flex padding-t-2 align-center">
          <CRSelect
            v-if="isModeAdd"
            :id="`alert-reservations-select`"
            v-model="selectedReservations"
            :items="contractReservations"
            label="Contract Reservations"
            item-text="displayName"
            item-value="reservationId"
            :loading="loading"
            class="padding-r-4"
            multiple
            return-object
            :rules="[
              (val) => isNotEmptyArray(val) || 'Reservation is Required',
            ]"
          />
          <CRInput
            v-else-if="reservation"
            v-model="reservation.managedId"
            label="Reservation"
            type="text"
            :loading="loading"
            class="padding-r-2"
            :readonly="true"
            hide-details
          />
          <v-switch
            :id="`alert-enabled-${externalId}`"
            v-model="enabled"
            label="Enabled"
            :loading="loading"
            :disable="isModeView"
            class="no-grow"
            hide-details
          />
        </div>
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        v-show="isModeView"
        id="alert-sidebar-edit-btn"
        :disabled="loading"
        class="alert-sidebar--action-btn"
        color="primary"
        @click="internalMode = 'edit'"
      >
        Edit
      </CRButton>
      <CRButton
        v-show="isModeEdit"
        id="alert-sidebar-delete-btn"
        :disabled="loading"
        class="alert-sidebar--delete-btn"
        text-color="red"
        @click="deleteHandler"
      >
        Delete
      </CRButton>
      <CRButton
        v-show="!isModeView"
        id="alert-save-btn"
        :disabled="loading"
        :loading="submitting"
        class="alert-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import alerts from '@/services/alerts'
import { filter } from '@/utils/filter'
import { isNotEmptyArray } from '@/utils/validators'
import { DateTime } from 'luxon'
import { getDatetimeFromDateAndTimeStrings } from '@/utils/time'

export default {
  props: {
    contract: {
      type: Object,
      default: null,
    },
    externalId: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalMode: null,
      loading: false,
      submitting: false,
      title: null,
      text: null,
      startEffectiveDate: null,
      startEffectiveTime: null,
      endEffectiveDate: null,
      endEffectiveTime: null,
      enabled: false,
      reservation: null,
      contractReservations: [],
      selectedReservations: [],
    }
  },
  computed: {
    isModeAdd() {
      return this.internalMode === 'add'
    },
    isModeView() {
      return this.internalMode === 'view'
    },
    isModeEdit() {
      return this.internalMode === 'edit'
    },
  },
  async mounted() {
    this.internalMode = this.mode
    if (this.isModeAdd) {
      this.retrieveContractReservations()
    } else {
      this.retrieveAlertDetail()
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    isNotEmptyArray,
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async retrieveAlertDetail() {
      this.loading = true
      try {
        const response = await alerts.getAlert(this.externalId)
        const alert = response.data.alert
        this.title = alert.title
        this.text = alert.text
        this.enabled = alert.enabled

        if (alert.reservationId) {
          const res = await this.$store.dispatch(
            'reservations/reservationById',
            alert.reservationId
          )

          this.reservation = res.data

          this.startEffectiveDate = DateTime.fromISO(alert.startEffectiveDate, {
            zone: this.reservation?.stops?.[0]?.address?.timeZone,
          }).toFormat('yyyy-MM-dd')
          this.startEffectiveTime = DateTime.fromISO(alert.startEffectiveDate, {
            zone: this.reservation?.stops?.[0]?.address?.timeZone,
          }).toFormat('HH:mm')
          this.endEffectiveDate = DateTime.fromISO(alert.endEffectiveDate, {
            zone: this.reservation?.stops?.[0]?.address?.timeZone,
          }).toFormat('yyyy-MM-dd')
          this.endEffectiveTime = DateTime.fromISO(alert.endEffectiveDate, {
            zone: this.reservation?.stops?.[0]?.address?.timeZone,
          }).toFormat('HH:mm')
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        this.showAlert({
          type: 'error',
          message: 'Error retrieving alert.',
        })
        return
      }
    },
    async retrieveContractReservations() {
      const filters = filter()

      if (!this.contract?.contractId) {
        return
      }
      this.loading = false
      const parentFilter = filters.createParent('and')
      const contractFilter = {
        column: {
          _t_id: 'contractFilter',
          prop: 'contractId',
          filterProp: 'trip.quote.contractId',
          filterType: 'eq',
        },
        value: this.contract.contractId,
      }

      filters.add(parentFilter, contractFilter)

      const params = {
        pageSize: -1,
        filters: filters.asQueryParams(),
      }

      const reservationData = await this.$store.dispatch(
        'reservations/reservationsTableView',
        params
      )
      this.contractReservations = reservationData?.data?.resultList.map(
        (res) => {
          return {
            ...res,
            displayName: `${res.managedId} (${res.tripRouteName})`,
          }
        }
      )
      this.loading = false
    },
    async submit() {
      this.submitting = true
      if (!this.$refs.form.validate()) {
        this.submitting = false
        return
      }

      if (this.isModeAdd) {
        await Promise.allSettled(
          this.selectedReservations.map(async (reservation) => {
            const payload = {
              alertType: 1,
              title: this.title,
              text: this.text,
              enabled: this.enabled,
              reservationId: reservation.reservationId,
              startEffectiveDate: getDatetimeFromDateAndTimeStrings(
                this.startEffectiveDate,
                this.startEffectiveTime,
                reservation.firstStopAddressTimeZone
              ),
              endEffectiveDate: getDatetimeFromDateAndTimeStrings(
                this.endEffectiveDate,
                this.endEffectiveTime,
                reservation.firstStopAddressTimeZone
              ),
            }
            const res = await alerts
              .createAlert(payload)
              .catch((e) => console.error(e))
          })
        ).catch((e) => {
          console.error(e)
          this.submitting = false
          this.$nextTick(() => {
            this.showAlert({
              type: 'error',
              message: 'Error creating alerts',
            })
          })
          return
        })
        this.submitting = false
        this.$nextTick(() => {
          this.showAlert({
            type: 'success',
            message: 'Alert(s) created.',
          })
        })
        this.close()
      } else if (this.isModeEdit) {
        if (!this.externalId) {
          this.submitting = false
          this.showAlert({
            type: 'error',
            message: 'Error updating alert.',
          })
          return
        }
        const payload = {
          alertType: 1,
          title: this.title,
          text: this.text,
          enabled: this.enabled,
          reservationId: this.reservation.reservationId,
          startEffectiveDate: getDatetimeFromDateAndTimeStrings(
            this.startEffectiveDate,
            this.startEffectiveTime,
            this.reservation.firstStopAddressTimeZone
          ),
          endEffectiveDate: getDatetimeFromDateAndTimeStrings(
            this.endEffectiveDate,
            this.endEffectiveTime,
            this.reservation.firstStopAddressTimeZone
          ),
        }
        const params = {
          externalId: this.externalId,
          payload,
        }
        try {
          const res = await alerts.updateAlert(params)
          this.submitting = false
          this.$nextTick(() => {
            this.showAlert({
              type: 'success',
              message: 'Alert updated.',
            })
          })
          this.close()
        } catch (e) {
          this.submitting = false
          this.showAlert({
            type: 'error',
            message: 'Error updating alert.',
          })
          return
        }
      }
    },
    async deleteHandler() {
      if (!this.externalId) {
        this.submitting = false
        this.showAlert({
          type: 'error',
          message: 'Error deleting alert.',
        })
        return
      }
      try {
        const res = await alerts.deleteAlert(this.externalId)
        this.submitting = false
        this.showAlert({
          type: 'success',
          message: 'Alert deleted.',
        })
        this.close()
      } catch (e) {
        this.submitting = false
        this.showAlert({
          type: 'error',
          message: 'Error deleting alert.',
        })
        return
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.alert-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    border-radius: 0;
  }
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
