var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-sidebar"},[_c('div',{staticClass:"alert-sidebar--content"},[_c('v-form',{ref:"form"},[_c('CRInput',{attrs:{"id":`alert-title-${_vm.externalId}`,"label":"Title","placeholder":"Enter alert title","loading":_vm.loading,"rules":[
          (val) => !!val || 'Title is Required',
          (val) => (!!val && val.length < 40) || 'Max 40 characters',
        ],"disabled":_vm.isModeView},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),(_vm.isModeView)?_c('div',[_c('p',{staticClass:"margin-a-0"},[_vm._v("Text")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}})]):_c('CRRichText',{staticClass:"padding-t-2 mb-4",attrs:{"id":`alert-text-${_vm.externalId}`,"note":_vm.text,"label":"Text","placeholder":"Enter alert text here.","loading":_vm.loading,"rows":4,"auto-grow":"","rules":[
          (val) => !!val || 'Title is Required',
          (val) => (!!val && val.length < 200) || 'Max 200 characters',
        ]},on:{"htmlchange":(val) => (_vm.text = val)}}),_c('div',{staticClass:"d-flex padding-t-2"},[_c('CRInput',{staticClass:"padding-r-2",attrs:{"label":"Start Date","type":"date","loading":_vm.loading,"disabled":_vm.isModeView,"clear-icon":"replay","clearable":true,"hide-details":""},model:{value:(_vm.startEffectiveDate),callback:function ($$v) {_vm.startEffectiveDate=$$v},expression:"startEffectiveDate"}}),_c('CRInput',{attrs:{"label":"Start Time","type":"time","loading":_vm.loading,"disabled":_vm.isModeView},model:{value:(_vm.startEffectiveTime),callback:function ($$v) {_vm.startEffectiveTime=$$v},expression:"startEffectiveTime"}})],1),_c('div',{staticClass:"d-flex padding-t-2"},[_c('CRInput',{staticClass:"padding-r-2",attrs:{"label":"End Date","type":"date","loading":_vm.loading,"disabled":_vm.isModeView,"clear-icon":"replay","clearable":true,"hide-details":""},model:{value:(_vm.endEffectiveDate),callback:function ($$v) {_vm.endEffectiveDate=$$v},expression:"endEffectiveDate"}}),_c('CRInput',{attrs:{"label":"End Time","type":"time","loading":_vm.loading,"disabled":_vm.isModeView},model:{value:(_vm.endEffectiveTime),callback:function ($$v) {_vm.endEffectiveTime=$$v},expression:"endEffectiveTime"}})],1),_c('div',{staticClass:"d-flex padding-t-2 align-center"},[(_vm.isModeAdd)?_c('CRSelect',{staticClass:"padding-r-4",attrs:{"id":`alert-reservations-select`,"items":_vm.contractReservations,"label":"Contract Reservations","item-text":"displayName","item-value":"reservationId","loading":_vm.loading,"multiple":"","return-object":"","rules":[
            (val) => _vm.isNotEmptyArray(val) || 'Reservation is Required',
          ]},model:{value:(_vm.selectedReservations),callback:function ($$v) {_vm.selectedReservations=$$v},expression:"selectedReservations"}}):(_vm.reservation)?_c('CRInput',{staticClass:"padding-r-2",attrs:{"label":"Reservation","type":"text","loading":_vm.loading,"readonly":true,"hide-details":""},model:{value:(_vm.reservation.managedId),callback:function ($$v) {_vm.$set(_vm.reservation, "managedId", $$v)},expression:"reservation.managedId"}}):_vm._e(),_c('v-switch',{staticClass:"no-grow",attrs:{"id":`alert-enabled-${_vm.externalId}`,"label":"Enabled","loading":_vm.loading,"disable":_vm.isModeView,"hide-details":""},model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1)],1)],1),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('div',[_c('CRButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModeView),expression:"isModeView"}],staticClass:"alert-sidebar--action-btn",attrs:{"id":"alert-sidebar-edit-btn","disabled":_vm.loading,"color":"primary"},on:{"click":function($event){_vm.internalMode = 'edit'}}},[_vm._v(" Edit ")]),_c('CRButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModeEdit),expression:"isModeEdit"}],staticClass:"alert-sidebar--delete-btn",attrs:{"id":"alert-sidebar-delete-btn","disabled":_vm.loading,"text-color":"red"},on:{"click":_vm.deleteHandler}},[_vm._v(" Delete ")]),_c('CRButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isModeView),expression:"!isModeView"}],staticClass:"alert-sidebar--action-btn",attrs:{"id":"alert-save-btn","disabled":_vm.loading,"loading":_vm.submitting,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }